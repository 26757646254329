import { template as template_998b3d6552dc4c7a93a9312a950d2cf0 } from "@ember/template-compiler";
const FKLabel = template_998b3d6552dc4c7a93a9312a950d2cf0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
