import { template as template_9eee71700bef44258b0696fa2e55d4e1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9eee71700bef44258b0696fa2e55d4e1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
